import { cn } from '~/utils'

export default function PulseDot({ className }: { className?: string }) {
  return (
    <span className={cn('relative inline-flex h-2.5 w-2.5', className)}>
      <span className="bg-primary absolute inline-flex h-full w-full animate-ping rounded-full opacity-75"></span>
      <span className="bg-primary relative inline-flex h-2.5 w-2.5 rounded-full"></span>
    </span>
  )
}
